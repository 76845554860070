import { useStorage } from '@vueuse/core'

interface TimezoneStorage {
  [clientUUID: string]: string;
}

export const useClientTimezone = (): {
  getClientTimezone: (uuid: string) => Promise<string>;
  setClientTimezone: (timezone: string, uuid: string) => void
} => {
  const clientUUID = ref('')
  const nuxtApp = useNuxtApp()

  // Store all timezones in one object
  const allTimezones = useStorage<TimezoneStorage>(
    'client_timezones',
    {},
    sessionStorage, // invalidates on tab close
    { listenToStorageChanges: true }
  )

  const getClientTimezone = async (uuid: string): Promise<string> => {
    if (allTimezones.value[uuid]) {
      nuxtApp.$logger.info('Returning cached timezone')
      return allTimezones.value[uuid]
    } else {
      nuxtApp.$logger.info('Fetching timezone')
      await fetchTimezone(uuid)
      return allTimezones.value[uuid]
    }
  }

  const fetchTimezone = async (uuid: string): Promise<void> => {
    const timezoneQuery = `query {
            domain: s_${(convertUUIDForPostgresSchema(uuid))}_tbl_domain_config {
              domain_config_timezone
            }
          }`
    const { data } = await nuxtApp.$gql.query(timezoneQuery)

    if (data.domain?.[0]?.domain_config_timezone) {
      setClientTimezone(data.domain[0].domain_config_timezone, uuid)
      nuxtApp.$dayjs.setDayjsTimezone(data.domain[0].domain_config_timezone)
    }
  }

  const clientTimezone = computed({
    get: () => allTimezones.value[clientUUID.value] || null,
    set: (newValue: string) => {
      allTimezones.value = {
        ...allTimezones.value,
        [clientUUID.value]: newValue
      }
    }
  })

  const setClientTimezone = (timezone: string, uuid: string): void => {
    // Call fetch on initialization
    clientUUID.value = uuid
    clientTimezone.value = timezone
  }

  return {
    getClientTimezone,
    setClientTimezone
  }
}
